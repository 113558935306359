import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Link } from 'react-router-dom';
import { SlArrowRight } from 'react-icons/sl';
import { TbHandMove } from 'react-icons/tb';
import { BsWhatsapp } from 'react-icons/bs';
import { PiEnvelopeThin } from 'react-icons/pi';
import { VscArrowSmallLeft, VscArrowSmallRight } from 'react-icons/vsc';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import {
  Container,
  Hero,
  Banner,
  AboutUs,
  Contact,
  Projects,
  Works,
} from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';

import logoDashboard from '~/assets/default/card-dashboard.png';
import logoData from '~/assets/default/card-dados.png';
import logoProjects from '~/assets/default/card-projetos.png';
import retangle from '~/assets/default/retangle-contact.svg';
import riskCircle from '~/assets/default/risk-circle.svg';
import video from '~/assets/videos/banner.mp4';

const Home: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);
  const [hoverId, setHoverId] = useState<number>();
  const [work, setwork] = useState('dashboard');
  const [continueBtn, setContinueBtn] = useState(false);
  const [leadId, setLeadId] = useState(0);

  const projects = useMemo(
    () => [
      {
        logo: logoDashboard,
        enterprise: '[Nome da empresa]',
        adress: 'São Paulo/SP',
        project: 'Dashboards',
        slug: 'dashboards',
      },
      {
        logo: logoData,
        enterprise: '[Nome da empresa]',
        adress: 'São Paulo/SP',
        project: 'Análise de dados',
        slug: 'analise-dados',
      },
      {
        logo: logoProjects,
        enterprise: '[Nome da empresa]',
        adress: 'São Paulo/SP',
        project: 'Projetos personalizados',
        slug: 'projetos',
      },
    ],
    []
  );

  const handleProjects = useCallback((index) => {
    setHoverId(index);
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('leads', data);

      setLeadId(response.data.id);
      setContinueBtn(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);

        if (errors.name || errors.email) {
          setContinueBtn(false);
        }
      } else {
        Swal.fire(
          'Oops...',
          'Ocorreu um erro tente novamente, por favor',
          'error'
        );
      }
    }
  }, []);

  const handleSubmitUpdate = useCallback(
    async (data, { reset }) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          company: Yup.string().required('A empresa é obrigatória'),
          message: Yup.string().required('A mensagem é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`leads/${leadId}`, data);

        Swal.fire('Tudo certo!', 'Enviado com sucesso!', 'success');

        reset();
        setContinueBtn(false);
        setLeadId(0);
        formRef.current?.reset();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefUpdate.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Oops...',
            'Ocorreu um erro tente novamente, por favor',
            'error'
          );
        }
      }
    },
    [leadId]
  );

  return (
    <Container>
      <Hero id="start" className="container-fluid">
        <div className="row">
          <div className="col-12 p-0 position-relative">
            <div className="line l-4" />
            <div className="line l-50" />
            <div className="line l-96" />
            <Banner
              src={video}
              autoPlay
              muted
              loop
              className="w-100 d-flex align-items-center py-lg-0"
            />
            <div className="position-absolute d-flex justify-content-center align-items-center title">
              <div className="px-1 px-md-0 w-md-50 text-center">
                <h1 className="h5 h4-sm h2-md h1-xl display-xxl-5 fw-lighter text-white text-center">
                  <b className="small normal-sm">
                    Transformamos dados em coisas úteis.
                  </b>
                  <small className="h6 h4-lg d-block fw-bold mt-2">
                    <span className="small normal-sm fw-bold">
                      <span className="small normal-sm fw-bold">
                        <span className="small normal-sm fw-bold">
                          Análise de dados | Automações | Serviços
                          personalizados
                        </span>
                      </span>
                    </span>
                  </small>
                </h1>
                <AnchorLink
                  href="#works"
                  offset={100}
                  className="h5 h4-xxl mt-3 mt-md-5 d-inline-flex align-items-center justify-content-center text-white text-center btn-more"
                >
                  Saiba mais
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <AboutUs>
        <div className="line l-4" />
        <div className="line l-50" />
        <div className="line l-96" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7 px-4 px-lg-3">
              <div className="dashboard mt-lg-5">
                <iframe
                  title="model"
                  src="https://bit.ly/pabloarcelino_dashboard_imoveis"
                  width="100%"
                  frameBorder="0"
                />
              </div>
              <p className="text-center h5 h4-sm fw-normal text-white mt-4 d-flex justify-content-center align-items-center">
                <span className="bg-hand d-flex d-sm-inline-block rounded-circle p-2 me-sm-3">
                  <TbHandMove color="#17C672" size={35} />
                </span>
                <span>
                  Interaja com o dashboard
                  <small className="h6 text-white d-block">
                    <span className="small normal-sm">
                      <small>(exemplo de uma das nossas entregas)</small>
                    </span>
                  </small>
                </span>
              </p>
            </div>
            <div
              id="about-us"
              className="col-lg-5 px-4 px-lg-3 pt-5 pt-lg-0 mt-5 mt-lg-0"
            >
              <h2 className="h4 h5-lg h4-xxl fw-semibold lt-space-5 text-lg-end text-white mb-5">
                SOBRE NÓS
              </h2>
              <p className="ps-lg-5 h5 h6-lg h5-xxl fw-lighter lh-base text-lg-end text-white mb-4 mb-lg-5">
                Somos obcecados por dados, engenharia&nbsp;e&nbsp;design.
              </p>
              <p className="ps-lg-5 h5 h6-lg h5-xxl fw-lighter lh-base text-lg-end text-white mb-4 mb-lg-5">
                Exploramos oportunidades para que você não deixe dinheiro na
                mesa.
              </p>
              <p className="ps-lg-0 h5 h6-lg h5-xxl fw-normal lh-base text-lg-end text-white">
                <span className="d-block">Fornecemos:</span> <br />
                <span className="d-block fw-bold mb-3">
                  Equipe de análise de dados para sua&nbsp;empresa
                </span>
                <span className="d-block fw-bold mb-3">
                  Automações personalizadas de processos&nbsp;e&nbsp;tarefas
                </span>
                <span className="d-block fw-bold mb-3">
                  Consultorias, serviços e projetos sob&nbsp;demanda
                </span>
              </p>
            </div>
            <div className="col-lg-6 ms-lg-auto mb-4 mb-lg-5">
              <div className="pt-5 justify-content-lg-end d-sm-flex">
                <AnchorLink
                  href="#contact"
                  offset={100}
                  className="d-block mb-5 mb-sm-0 ps-4 ps-lg-3 ps-xl-4 py-2 border-start text-white h5 h4-xxl fw-normal mb-0"
                >
                  Entrar em contato <SlArrowRight size={20} color="#fff" />
                </AnchorLink>
                <Link
                  to={`${process.env.PUBLIC_URL}/portifolio/todos`}
                  className="d-block ms-sm-5 ps-3 ps-xl-4 py-2 border-start text-white h5 h4-xxl fw-normal mb-0"
                >
                  Ver Cases <SlArrowRight size={20} color="#fff" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-5 py-4 bg-track overflow-hidden">
              <div className="track py-3 py-lg-4">
                - Tenha uma equipe de análise de dados na sua empresa –
                Profissionais especializados – Mais barato do que um analista –
                Mapeie seus KPIs – Identifique gargalos – Faça previsões –
                Receba relatórios de melhorias – Dashboards para impactar seus
                clientes – Faça automações de processos e tarefas
              </div>
            </div>
          </div>
        </div>
      </AboutUs>
      <Projects>
        <div className="line l-4" />
        <div className="line l-50" />
        <div className="line l-96" />
        <div className="background-stars">
          <div className="stars overlay" />
          <div className="twinkling overlay" />
          <div className="lighter-bg overlay" />
        </div>
        <div id="projects" className="container">
          <div className="row">
            <div className="px-4 px-lg-3 col-12 position-relative px-lg-5 d-flex align-items-center justify-content-between mb-5">
              <h2 className="h4 h5-lg h4-xxl lt-space-5 text-white mb-0 text-uppercase">
                Alguns trabalhos da Muove
              </h2>
              <Link
                to={`${process.env.PUBLIC_URL}/portifolio/todos`}
                className=" h5 h4-xxl mb-0 d-none d-lg-inline-flex align-items-center justify-content-center text-white text-center btn-more"
              >
                Ver todos
              </Link>
            </div>
            <div className="overflow-auto">
              <div className="d-flex width-projects px-0">
                {projects.map((project, index) => (
                  <div className="col-lg-4 px-3">
                    <Link
                      className="position-relative d-flex h-100 w-100"
                      to={`${process.env.PUBLIC_URL}/portifolio/${project.slug}`}
                      onMouseEnter={() => handleProjects(index)}
                      onMouseLeave={() => handleProjects(-1)}
                    >
                      <svg
                        width="402"
                        height="452"
                        viewBox="0 0 402 452"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1098_1171)">
                          <path
                            d="M62.9369 20.8698L35.3695 37.9071C21.8077 46.2885 13.5527 61.0946 13.5527 77.0373V415C13.5527 435.435 30.1182 452 50.5527 452H270.397C282.684 452 294.641 448.03 304.488 440.682L378.644 385.341C393.061 374.582 401.553 357.649 401.553 339.66V51C401.553 30.5655 384.987 14 364.553 14L87.1201 14C78.5775 14 70.2037 16.3788 62.9369 20.8698Z"
                            fill="black"
                          />
                          <path
                            opacity="0.4"
                            d="M190.353 -59.1979C159.839 -170.956 -65.9697 -94.4499 -93.885 9.73139C-121.222 128.289 155.408 132.509 190.617 -28.7482C192.802 -38.7539 193.05 -49.318 190.353 -59.1979Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeDasharray="10 10"
                          />
                          <g clipPath="url(#clip1_1098_1171)">
                            <circle
                              cx="-33.9473"
                              cy="503.5"
                              r="273"
                              stroke="white"
                            />
                            <circle
                              cx="-34.4473"
                              cy="503"
                              r="260.5"
                              stroke="white"
                            />
                            <circle
                              cx="-34.9473"
                              cy="502.5"
                              r="248"
                              stroke="white"
                            />
                            <circle
                              cx="-35.4473"
                              cy="502"
                              r="235.5"
                              stroke="white"
                            />
                            <circle
                              cx="-35.9473"
                              cy="501.5"
                              r="223"
                              stroke="white"
                            />
                            <circle
                              cx="-36.4473"
                              cy="501"
                              r="210.5"
                              stroke="white"
                            />
                            <circle
                              cx="-36.9473"
                              cy="500.5"
                              r="198"
                              stroke="white"
                            />
                            <circle
                              cx="-37.4473"
                              cy="500"
                              r="185.5"
                              stroke="white"
                            />
                            <circle
                              cx="-37.9473"
                              cy="499.5"
                              r="173"
                              stroke="white"
                            />
                            <circle
                              cx="-38.4473"
                              cy="499"
                              r="160.5"
                              stroke="white"
                            />
                            <circle
                              cx="-38.9473"
                              cy="498.5"
                              r="148"
                              stroke="white"
                            />
                            <circle
                              cx="-39.4473"
                              cy="498"
                              r="135.5"
                              stroke="white"
                            />
                            <circle
                              cx="-39.9473"
                              cy="497.5"
                              r="123"
                              stroke="white"
                            />
                            <circle
                              cx="-40.4473"
                              cy="497"
                              r="110.5"
                              stroke="white"
                            />
                            <circle
                              cx="-40.9473"
                              cy="496.5"
                              r="98"
                              stroke="white"
                            />
                            <circle
                              cx="-41.4473"
                              cy="496"
                              r="85.5"
                              stroke="white"
                            />
                          </g>
                        </g>
                        <path
                          d="M49.9369 7.86984L22.3695 24.9071C8.8077 33.2885 0.552734 48.0946 0.552734 64.0373V402C0.552734 422.435 17.1182 439 37.5527 439H257.397C269.684 439 281.641 435.03 291.488 427.682L365.644 372.341C380.061 361.582 388.553 344.649 388.553 326.66V38C388.553 17.5655 371.987 1.00004 351.553 1.00004L74.1201 1C65.5775 1 57.2037 3.3788 49.9369 7.86984Z"
                          stroke={`${
                            hoverId === index ? '#4E4E4E' : 'transparent'
                          }`}
                        />
                        <rect
                          x="311.553"
                          y="360"
                          width="72"
                          height="72"
                          rx="36"
                          fill={`${hoverId === index ? '#707070' : '#202020'}`}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M349.475 385.922C349.904 385.492 350.601 385.492 351.03 385.922L359.831 394.722C360.26 395.151 360.26 395.848 359.831 396.277L351.03 405.078C350.601 405.507 349.904 405.507 349.475 405.078C349.045 404.648 349.045 403.951 349.475 403.522L356.397 396.6H337.053C336.445 396.6 335.953 396.107 335.953 395.5C335.953 394.892 336.445 394.4 337.053 394.4H356.397L349.475 387.477C349.045 387.048 349.045 386.351 349.475 385.922Z"
                          fill="white"
                        />
                        <defs>
                          <clipPath id="clip0_1098_1171">
                            <rect
                              width="388"
                              height="438"
                              fill="white"
                              transform="translate(13.5527 14)"
                            />
                          </clipPath>
                          <clipPath id="clip1_1098_1171">
                            <rect
                              x="-13.4473"
                              y="353"
                              width="69"
                              height="69"
                              rx="34.5"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <img
                        className="position-absolute"
                        src={project.logo}
                        alt="Project"
                      />

                      <h4 className="position-absolute h5 h4-xxl text-white fw-lighter">
                        {project.project}
                      </h4>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 text-center my-5 d-lg-none">
              <Link
                to={`${process.env.PUBLIC_URL}/portifolio/todos`}
                className="h5 h4-xxl mb-0 d-inline-flex d-lg-none align-items-center justify-content-center text-white text-center btn-more"
              >
                Ver todos
              </Link>
            </div>
          </div>
        </div>
      </Projects>
      <Works>
        <div className="line l-4 d-none d-lg-block" />
        <div className="line l-50 d-none d-lg-block" />
        <div className="line l-96 d-none d-lg-block" />
        <div id="works" className="container">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-start py-5 px-5 px-lg-0">
              <svg
                viewBox="0 0 433 442"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M319.517 198.338V198.061L319.283 197.914L135.998 82.8411V31.4786L198.014 0.569545L432.051 140.545V378.919L354.164 418.963L319.517 396.092V198.338Z"
                  fill={`${work === 'projects' ? '#0E5F38' : '#202020'}`}
                  stroke="#4E4E4E"
                  onMouseEnter={() => setwork('projects')}
                />
                <path
                  d="M141.31 287.311V287.044L141.089 286.895L52.2803 227.46V163.708L102.934 137.902L248.88 234.04V416.335L198.822 440.991L141.31 404.202V287.311Z"
                  fill={`${
                    work === 'data' || work === 'projects'
                      ? '#129a59'
                      : '#202020'
                  }`}
                  stroke="#4E4E4E"
                  onMouseEnter={() => setwork('data')}
                />
                <path
                  d="M89.8872 358.973L68.1533 397.605H21.7228L0.57215 358.965L21.1375 322.586H67.4697L89.8872 358.973Z"
                  fill={`${
                    work === 'dashboard' ||
                    work === 'data' ||
                    work === 'projects'
                      ? '#00FF9A'
                      : '#202020'
                  }`}
                  stroke="#4E4E4E"
                  onMouseEnter={() => setwork('dashboard')}
                />
              </svg>
            </div>
            <div className="col-lg-6 px-4 px-lg-3">
              <div className="d-lg-flex justify-content-between mt-5">
                <button
                  type="button"
                  className={`${
                    work === 'dashboard' && 'active'
                  } d-block mb-4 mb-lg-0 fw-normal text-white bg-transparent text-start text-lg-center`}
                  onMouseEnter={() => setwork('dashboard')}
                >
                  Equipe de análise de&nbsp;dados para&nbsp;empresas
                </button>
                <button
                  type="button"
                  className={`${
                    work === 'data' && 'active'
                  } d-block mb-4 mb-lg-0 fw-normal text-white bg-transparent text-start text-lg-center`}
                  onMouseEnter={() => setwork('data')}
                >
                  Automações de processos e&nbsp;tarefas
                </button>
                <button
                  type="button"
                  className={`${
                    work === 'projects' && 'active'
                  } d-block fw-normal text-white bg-transparent text-start text-lg-center`}
                  onMouseEnter={() => setwork('projects')}
                >
                  Consultorias e serviços sob&nbsp;demanda
                </button>
              </div>
              <div className="mt-5 pe-lg-5">
                {work === 'dashboard' && (
                  <>
                    <p>
                      <b>
                        Equipe de análise de dados para sua empresa composta
                        por:
                      </b>
                    </p>
                    <ul>
                      <li>Cientistas de dados;</li>
                      <li>Analistas de dados;</li>
                      <li>Engenheiros;</li>
                      <li>Designers;</li>
                      <li>Programadores;</li>
                      <li>Arquitetos;</li>
                      <li>Consultores;</li>
                    </ul>
                    <p>
                      <b>Fazemos 4 tipos de análises:</b>
                    </p>
                    <ul>
                      <li>
                        Descritiva (raio X completo do status atual da empresa);
                      </li>
                      <li>Causas (identificação de gargalos e vantagens);</li>
                      <li>
                        Preditiva (modelos de previsão com base em dados
                        históricos);
                      </li>
                      <li>Prescritiva (plano de ação para melhorias).</li>
                    </ul>
                  </>
                )}
                {work === 'data' && (
                  <>
                    <ul>
                      <li>
                        Mapeamento, desenho e automação de processos para
                        empresas;
                      </li>
                      <li>
                        Criação de scripts de automação para tarefas
                        repetitivas;
                      </li>
                      <li>
                        Implementação de RPA (Robotic Process Automation);
                      </li>
                      <li>
                        Manutenção, suporte e otimização de sistemas
                        automatizados;
                      </li>
                      <li>
                        Integração de inteligência artificial e aprendizado de
                        máquina;
                      </li>
                    </ul>
                  </>
                )}
                {work === 'projects' && (
                  <>
                    <p>
                      <b>Dashboards impactantes</b>
                    </p>
                    <p>
                      Entregamos dashboards graficamente atraentes e com foco na
                      maximização da experiência do usuário para extrair
                      informações valiosas a partir de sua visualização.
                    </p>
                    <p>
                      Vinculamos modelos 3D, mapas e outras visualizações
                      personalizadas para uma apresentação impactante.
                    </p>
                    <p>
                      <b>Consultorias</b>
                    </p>
                    <p>
                      Consultoria especializada com foco na transformação
                      digital e otimização de processos, análise de dados e
                      tecnologias.
                    </p>
                    <p>
                      Nossa equipe técnica, composta por especialistas com vasta
                      experiência, garante a entrega de insights estratégicos e
                      soluções sob medida para maximizar a eficiência e o
                      desempenho nas áreas de atuação da empresa.
                    </p>
                    <p>
                      <b>Aplicativos personalizados</b>
                    </p>
                    <p>
                      Desenvolvemos aplicativos sob medida, projetados para
                      atender às demandas exclusivas de cada cliente, com
                      funcionalidades específicas que otimizarão o fluxo de
                      trabalho e aprimorarão a tomada de decisão. Integramos
                      dados de várias fontes para criar ferramentas intuitivas,
                      acessíveis em qualquer dispositivo, que facilitam o
                      acompanhamento de métricas essenciais e o controle de
                      processos em tempo real.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Works>
      <Contact>
        <div className="line l-4" />
        <div className="line l-50" />
        <div className="line l-96" />
        <div className="bg-circle" />
        <div id="contact" className="container">
          <div className="row">
            <div className="col-12 px-3 bg-contact">
              <img
                src={retangle}
                alt="Retangulo"
                className="position-absolute"
              />
              <img
                src={riskCircle}
                alt="Circle"
                className="position-absolute"
              />
              <div className="row py-5 my-5">
                <div className="col-lg-5 mx-lg-auto px-3 px-sm-5 px-lg-3">
                  <h3 className="h6 h5-xl text-white fw-lighter">CONTATO</h3>
                  <h2 className="h3 h2-xl fw-semibold text-white mb-5">
                    Entre em contato <br />
                    conosco
                  </h2>
                  <a
                    href="mailto:contato@muove.com.br"
                    target="_blank"
                    rel="noreferrer"
                    className="h6 h5-md text-white ps-4 mt-4 mb-5 d-inline-flex"
                  >
                    <PiEnvelopeThin size={25} color="#fff" className="me-3" />
                    contato@muove.com.br
                  </a>
                  <div className="bg-tel mt-lg-4 mb-5 mb-lg-0">
                    <a
                      href="https://api.whatsapp.com/send?phone=5511912906767&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Muove.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
                      target="_blank"
                      rel="noreferrer"
                      className="h6 h5-md text-white ps-4"
                    >
                      <BsWhatsapp size={22} color="#fff" className="me-3" />{' '}
                      (11) 9.1290-6767
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 mx-lg-auto position-relative px-3 px-sm-5 px-lg-3">
                  {continueBtn && (
                    <button
                      type="button"
                      className="btn-back border-0 bg-transparent position-absolute"
                      onClick={() => setContinueBtn(false)}
                    >
                      <VscArrowSmallLeft size={30} color="#fff" />
                    </button>
                  )}
                  <div className="p-4 p-xl-5 form">
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className={continueBtn ? 'd-none' : 'd-block'}
                    >
                      <label className="mt-4 mb-5">
                        <Input name="name" placeholder="Seu nome" />
                      </label>
                      <label className="mb-5">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Seu melhor e-mail"
                        />
                      </label>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="h5 h4-xl text-white bg-transparent border-0 py-2 mb-0"
                        >
                          Continuar{' '}
                          <VscArrowSmallRight size={47} color="#fff" />
                        </button>
                      </div>
                    </Form>
                    <Form
                      ref={formRefUpdate}
                      onSubmit={handleSubmitUpdate}
                      className={continueBtn ? 'd-block' : 'd-none'}
                    >
                      <label className="mt-4 mb-5">
                        <Input name="company" placeholder="Empresa" />
                      </label>
                      <label className="mb-5">
                        <Textarea
                          name="message"
                          placeholder="Mensagem"
                          padding="5px 0px"
                          rows={1}
                        />
                      </label>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="h5 h4-xl text-white bg-transparent border-0 py-2 mb-0"
                        >
                          Enviar <VscArrowSmallRight size={47} color="#fff" />
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Contact>
    </Container>
  );
};

export default Home;
