import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Menu } from './styles';
import logo from '~/assets/logos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [selection, setSelection] = useState('start');

  useEffect(() => {
    setActive(false);
  }, [location]);

  useEffect(() => {
    setActive(false);
    if (location.hash) {
      setSelection(location.hash.replace('#', ''));
      history.push(process.env.PUBLIC_URL);
    }
  }, [history, location.hash]);

  useEffect(() => {
    scroller.scrollTo(selection, {
      smooth: 'easeInOutQuart',
      offset: -100,
    });

    setActive(false);
  }, [selection]);

  const LinkComponent = useMemo<any>(() => {
    if (location.pathname !== '/') {
      return NavLink;
    }

    return AnchorLink;
  }, [location.pathname]);

  return (
    <>
      <Container active={active}>
        <div
          id="menu"
          className="container-fluid py-3 py-lg-0 menu-fix d-lg-flex align-items-center"
        >
          <div className="row w-lg-100">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div>
                      <NavLink to="/">
                        <img src={logo} alt="logo" className="logo" />
                      </NavLink>
                    </div>
                    <Menu active={active}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="menu-group">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-center">
                          <LinkComponent
                            href="#start"
                            to={`${process.env.PUBLIC_URL}/`}
                            className={`${
                              selection === 'start' && 'active'
                            } btn-menu h2 h6-lg h5-xl mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelection('start');
                              setActive(false);
                            }}
                          >
                            Início
                          </LinkComponent>
                          <LinkComponent
                            href="#about-us"
                            to={`${process.env.PUBLIC_URL}/#about-us`}
                            className={`${
                              selection === 'about-us' && 'active'
                            } btn-menu h2 h6-lg h5-xl mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelection('about-us');
                              setActive(false);
                            }}
                            offset="100"
                          >
                            Sobre nós
                          </LinkComponent>
                          <LinkComponent
                            href="#projects"
                            to={`${process.env.PUBLIC_URL}/#projects`}
                            className={`${
                              selection === 'projects' && 'active'
                            } btn-menu h2 h6-lg h5-xl mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelection('projects');
                              setActive(false);
                            }}
                          >
                            Cases
                          </LinkComponent>
                          <LinkComponent
                            href="#works"
                            to={`${process.env.PUBLIC_URL}/#works`}
                            className={`${
                              selection === 'works' && 'active'
                            } btn-menu h2 h6-lg h5-xl mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelection('works');
                              setActive(false);
                            }}
                          >
                            O que fazemos
                          </LinkComponent>
                          <LinkComponent
                            href="#contact"
                            to={`${process.env.PUBLIC_URL}/#contact`}
                            className={`${
                              selection === 'contact' && 'active'
                            } btn-menu h2 h6-lg h5-xl mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center`}
                            onClick={() => {
                              setSelection('contact');
                              setActive(false);
                            }}
                          >
                            Contato
                          </LinkComponent>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
